































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class Terms extends Vue { }
